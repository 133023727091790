import { FC, ReactNode, ElementType, Fragment, Children, useState } from "react";
import classNames from "classnames";
import { Button, Tag } from "../";
import React from "react";
import { QualifierStatusType } from "services/dashboard.types";
import { useSelector } from "react-redux";
import { RootState } from "store";
import useDownload from "hooks/useDownload";
import usePaCode from "hooks/usePACode";

interface AccordionPanelProp {
	label: string | JSX.Element | ReactNode;
	className?: string;
	countryCode: string;
	tag?: ElementType | keyof JSX.IntrinsicElements | string;
	id: string;
	dealerStatus?: QualifierStatusType;
	statusText?: string;
	secondaryStatusText?: string;
	secondaryHighlightText?: string;
	deadLine?: string;
	innerText?: string;
	panelLinkText?: string;
	panelLinkPath?: string;
	panelLinks?: { linkText: string; linkPath: string }[];
	mobile?: boolean;
	children?: JSX.Element | JSX.Element[];
	key?: number;
	attestationId?: string;
}

interface AccordionButtonProp {
	label: string | JSX.Element | ReactNode;
	id: string;
	countryCode: string;
	className?: string;
	dealerStatus?: QualifierStatusType;
	deadLine?: string;
	innerText?: string;
	statusText?: string;
	secondaryHighlightText?: string;
	secondaryStatusText?: string;
	attestationId?: string;
	panelLinkText?: string;
	panelLinkPath?: string;
	panelLinks?: { linkText: string; linkPath: string }[];
	mobile?: boolean;
	tag?: ElementType | keyof JSX.IntrinsicElements | string;
}

interface AccordionContentProp {
	id: string;
	className?: string;
	tag?: ElementType | keyof JSX.IntrinsicElements | string;
	children?: ReactNode | ReactNode[] | JSX.Element | JSX.Element[];
}

const AccordionPanelButton: FC<AccordionButtonProp> = (props) => {
	const { id, label, countryCode, className, deadLine, dealerStatus, innerText, statusText, secondaryStatusText, secondaryHighlightText, panelLinkText, panelLinkPath, panelLinks = [], mobile, attestationId } = props;
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);
	const completeIcon = "/assets/icons/meDDicon/icon-complete.svg";
	const incompleteIcon = "/assets/icons/meDDicon/icon-not-complete.svg";
	const DCSIcon = "/assets/icons/meDDicon/icon-deferred.svg";
	const waiverIcon = "/assets/icons/meDDicon/icon-on-waiver.svg";
	const loaIcon = "/assets/icons/meDDicon/icon-LOA.svg";
	let statusIconDisplay = "";
	let innerTextClasses = "";
	let statusColor = "";

	const { download } = useDownload();
	const paCode = usePaCode();

	switch (dealerStatus) {
		case "Complete":
			statusIconDisplay = completeIcon;
			innerTextClasses = "fmc-accordion__button-title ";
			statusColor = "green";
			break;
		case "InComplete":
			statusIconDisplay = incompleteIcon;
			innerTextClasses = "fmc-accordion__button-title ";
			statusColor = "orange";
			break;
		case "ActionRequired":
			statusIconDisplay = incompleteIcon;
			innerTextClasses = "fmc-accordion__button-title ";
			statusColor = "orange";
			break;
		case "Deferred":
			statusIconDisplay = DCSIcon;
			innerTextClasses = "fmc-accordion__button-title dash-inner-gray";
			statusColor = "gray";
			break;
		case "OnWaiver":
			statusIconDisplay = completeIcon;
			innerTextClasses = "fmc-accordion__button-title ";
			statusColor = "green";
			break;
		case "LOA":
			statusIconDisplay = loaIcon;
			innerTextClasses = "fmc-accordion__button-title  ";
			statusColor = "green";
			break;
		default:
			"";
			innerTextClasses = "fmc-accordion__button-title";
			break;
	}

	const calendarIcon = "/assets/icons/meDDicon/icon-calendar.png";

	const ClassName = classNames(["fmc-accordion__button"], className);
	const narrowMD = (windowWidth as number) >= 1024 && (windowWidth as number) <= 1255;

	return (
		<>
			{mobile ? (
				<Tag tag={"button"} className={ClassName} id={`${id}-button`} aria-controls={`${id}-content`}>
					<div className={"fds-flex fds-flex-direction--column"}>
						{typeof label === "string" ? (
							<Tag tag={"span"} className={innerTextClasses}>
								{label && (
									<>
										<div>
											<Tag tag={"img"} src={statusIconDisplay} className={"dash-status-icon"} />
											{label} {statusText && <span>:</span>}
										</div>
										<div>
											{statusText && <span className={`fds-p--l-2 dash-panel-status dash-panel-font-${statusColor}`}>{statusText} </span>}
											{secondaryHighlightText && <span className={`dash-panel-status dash-panel-font-${statusColor}`}> - {secondaryHighlightText}</span>}
											{secondaryStatusText && <span className={`dash-panel-status dash-panel-font-gray ${secondaryStatusText === "07/31/2024" ? "fds-weight--bold" : ""}`}> - {secondaryStatusText}</span>}
										</div>
									</>
								)}
							</Tag>
						) : (
							<Fragment>{label}</Fragment>
						)}

						{typeof panelLinkText === "string" ? (
							<>
								<Tag href={panelLinkPath} tag={"a"} className={"accordion-inner-link"} target="_blank">
									{panelLinkText}
								</Tag>
							</>
						) : (
							<Fragment>{panelLinkText}</Fragment>
						)}

						{!!attestationId && (
							<Tag href={`/${paCode}/attestations`} tag={"a"} className={"accordion-inner-link"} target="_blank">
								View Attestation
							</Tag>
						)}

						{panelLinks?.map((panel, idx) => (
							<Tag key={idx} href={panel.linkPath} tag={"a"} className={"accordion-inner-link"} target="_blank">
								{panel.linkText}
							</Tag>
						))}
					</div>

					{typeof deadLine === "string" ? (
						<Tag tag={"span"} className={`accordion-inner-text`}>
							<Tag tag={"img"} src={calendarIcon} className={"fds-p--t-1"} /> Deadline: {deadLine}
						</Tag>
					) : (
						<Fragment>{deadLine}</Fragment>
					)}

					{typeof innerText === "string" ? (
						<Tag tag={"span"} className={`accordion-inner-text`}>
							{innerText}
						</Tag>
					) : (
						<Fragment>{innerText}</Fragment>
					)}

					<Tag tag={"span"} className={`fmc-accordion__button-expand fds-icon`} />
				</Tag>
			) : (
				<>
					<Tag tag={"button"} className={`fds-position--relative ${ClassName} ${narrowMD ? "narrowMDButton" : ""}`} id={`${id}-button`} aria-controls={`${id}-content`}>
						{narrowMD ? (
							<>
								<div className={"fds-flex fds-flex-direction--column"}>
									<div>
										{typeof label === "string" ? (
											<Tag tag={"span"} className={innerTextClasses}>
												<Tag tag={"img"} src={statusIconDisplay} className={"dash-status-icon"} />
												{label} {statusText && <span>:</span>}
												{statusText && <span className={`fds-p--l-1 dash-panel-status dash-panel-font-${statusColor}`}>{statusText}</span>}
												{secondaryHighlightText && <span className={`dash-panel-status dash-panel-font-${statusColor}`}> - {secondaryHighlightText}</span>}
												{secondaryStatusText && <span className={`dash-panel-status dash-panel-font-gray ${secondaryStatusText === "07/31/2024" ? "fds-weight--bold" : ""}`}> - {secondaryStatusText}</span>}
											</Tag>
										) : (
											<Fragment>{label}</Fragment>
										)}
									</div>

									<div className={"fds-p--r-1"}>
										{typeof panelLinkText === "string" ? (
											<div className={"accordion-inner-link-container"}>
												<div className={"  fds-m--l-3 fds-m--y-1"}>
													<Tag href={panelLinkPath} tag={"a"} className={"accordion-inner-link"} target="_blank">
														{panelLinkText}
													</Tag>
												</div>
											</div>
										) : (
											<Fragment>{panelLinkText}</Fragment>
										)}

										{panelLinks?.length > 0 && (
											<div className={"accordion-inner-link-container"}>
												{panelLinks?.map((panel, idx) => (
													<div className={"  fds-m--l-3 fds-m--y-1"}>
														<Tag href={panel.linkPath} key={idx} tag={"a"} className={"accordion-inner-link"} target="_blank">
															{panel.linkText}
														</Tag>
													</div>
												))}
											</div>
										)}

										{!!attestationId && (
											<div className={"accordion-inner-link-container"}>
												<div className={"  fds-m--l-3 fds-m--y-1"}>
													<Tag href={`/${paCode}/attestations`} tag={"a"} className={"accordion-inner-link"} target="_blank">
														View Attestation
													</Tag>
												</div>
											</div>
										)}

										{typeof deadLine === "string" ? (
											<Tag tag={"span"} className={`accordion-inner-text`}>
												<Tag tag={"img"} src={calendarIcon} className={"fds-p--t-1"} /> Deadline: {deadLine}
											</Tag>
										) : (
											<Fragment>{deadLine}</Fragment>
										)}
									</div>
								</div>

								{typeof innerText === "string" ? (
									<Tag tag={"span"} className={`accordion-inner-text`}>
										{innerText}
									</Tag>
								) : (
									<Fragment>{innerText}</Fragment>
								)}

								<Tag tag={"span"} className={`fmc-accordion__button-expand fds-icon`} />
							</>
						) : (
							<>
								{typeof label === "string" ? (
									<Tag tag={"span"} className={innerTextClasses}>
										<Tag tag={"img"} src={statusIconDisplay} className={"dash-status-icon"} />
										{label} {statusText && <span>:</span>}
										{statusText && <span className={`fds-p--l-1 dash-panel-status dash-panel-font-${statusColor}`}>{statusText}</span>}
										{secondaryHighlightText && <span className={`dash-panel-status dash-panel-font-${statusColor}`}> - {secondaryHighlightText}</span>}
										{secondaryStatusText && <span className={`dash-panel-status dash-panel-font-gray ${secondaryStatusText === "07/31/2024" ? "fds-weight--bold" : ""}`}> - {secondaryStatusText}</span>}
									</Tag>
								) : (
									<Fragment>{label}</Fragment>
								)}

								{typeof panelLinkText === "string" ? (
									<div className={"accordion-inner-link-container"}>
										<Tag href={panelLinkPath} tag={"a"} className={"accordion-inner-link"} target="_blank">
											{panelLinkText}
										</Tag>
									</div>
								) : (
									<Fragment>{panelLinkText}</Fragment>
								)}

								{panelLinks?.length > 0 && (
									<div className={"accordion-inner-link-container"}>
										{panelLinks?.map((panel, idx) => (
											<Tag href={panel.linkPath} key={idx} tag={"a"} className={"accordion-inner-link"} target="_blank">
												{panel.linkText}
											</Tag>
										))}
									</div>
								)}

								{!!attestationId && (
									<div className={"accordion-inner-link-container"}>
										<Tag href={`/${paCode}/attestations`} tag={"a"} className={"accordion-inner-link"} target="_blank">
											View Attestation
										</Tag>
									</div>
								)}

								{typeof deadLine === "string" ? (
									<Tag tag={"span"} className={`accordion-inner-text`}>
										<Tag tag={"img"} src={calendarIcon} className={"fds-p--t-1"} /> Deadline: {deadLine}
									</Tag>
								) : (
									<Fragment>{deadLine}</Fragment>
								)}

								{typeof innerText === "string" ? (
									<Tag tag={"span"} className={`accordion-inner-text`}>
										{innerText}
									</Tag>
								) : (
									<Fragment>{innerText}</Fragment>
								)}

								<Tag tag={"span"} className={`fmc-accordion__button-expand fds-icon`} />
							</>
						)}
					</Tag>
				</>
			)}
		</>
	);
};

const AccordionPanelContent: FC<AccordionContentProp> = (props) => {
	const { id, tag = "div", children, ...attributes } = props;

	return (
		<Tag tag={tag} className={"fmc-accordion__content"} id={`${id}-content`} role={"region"} aria-hidden={false} {...attributes}>
			{Children.map(children, (child: any) => {
				return child;
			})}
		</Tag>
	);
};

export const AccordionPanel: FC<AccordionPanelProp> = (props) => {
	const { tag = "div", id, countryCode, children, label, deadLine, dealerStatus, innerText, statusText, secondaryStatusText, secondaryHighlightText, panelLinkText, panelLinkPath, panelLinks, mobile, attestationId, ...attributes } = props;

	return (
		<Tag tag={tag} className={"fmc-accordion__panel"} {...attributes}>
			<AccordionPanelButton
				id={id}
				label={label}
				countryCode={countryCode}
				deadLine={deadLine}
				dealerStatus={dealerStatus}
				innerText={innerText}
				statusText={statusText}
				secondaryStatusText={secondaryStatusText}
				secondaryHighlightText={secondaryHighlightText}
				panelLinkText={panelLinkText}
				panelLinkPath={panelLinkPath}
				mobile={mobile}
				panelLinks={panelLinks}
				attestationId={attestationId}
			/>
			<Tag tag={"div"} className={"fmc-accordion__body"}>
				<AccordionPanelContent id={id}>{children}</AccordionPanelContent>
			</Tag>
		</Tag>
	);
};
