/* eslint-disable sonarjs/prefer-immediate-return */
import formData from "../../../../types/LOAFormTypes";
import LOAStep1 from "../LOAStep1";
import LOAStep2 from "../LOAStep2";
import LOAStep3 from "../LOAStep3";
import LOAStep3Confirmation from "./LOAStep3Confirmation";
import LOACompletion from "../LOACompletion";
import React, { useState } from "react";

interface FormInputsProps {
	page: number;
	formData: formData;
	dataVerbiage: string[];
	handleChange: any;
	fileError: string;
	associationError: string;
	disableOptions: string[];
	identifyPage: string;
	fileName: string;
	showLoader: boolean;
}

const FormInputs = (props: FormInputsProps) => {
	const { page, handleChange, formData, dataVerbiage, fileError, associationError, disableOptions, fileName, showLoader } = props;

	return (
		<div>
			{page === 0 ? <LOAStep1 handleChange={handleChange} formData={formData} disableOptions={disableOptions} page={page} identifyPage="upload" /> : <></>}
			{page === 1 ? <LOAStep2 formData={formData} handleChange={handleChange} fileError={fileError} fileName={fileName} /> : <></>}
			{page === 2 ? <LOAStep3 formData={formData} handleChange={handleChange} dataVerbiage={dataVerbiage} associationError={associationError} /> : <></>}
			{page === 3 ? <LOAStep3Confirmation formData={formData} dataVerbiage={dataVerbiage} /> : <></>}
			{page === 4 ? <LOACompletion showLoader={showLoader} /> : <></>}
		</div>
	);
};

export default FormInputs;
