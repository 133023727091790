import React, { useEffect, useState } from "react";
import formData from "../../../../types/LOAFormTypes";
import { Col } from "../..";
import { useGetCurrentAssociationDataQuery } from "services/current-association";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useAuth } from "providers/AuthProvider";
// import { setDocument } from 'reducers/document.slice';
interface EditChosenAssociationsProps {
	formData: formData;
	dataVerbiage: string[];
}
const EditChosenAssociations = (props: EditChosenAssociationsProps) => {
	const tokenInfo: any = useAuth();
	const { formData, dataVerbiage } = props;
	const [editUser, setEditUser]: any = useState({});
	const [complianceData, setComplianceData]: any = useState([]);
	const { pacode, marketArea, region, salesCode, wslId } = useSelector((state: RootState) => state.pacode);

	const { countryCode, preferredLanguage } = useSelector((state: RootState) => state.localePreferences);

	useEffect(() => {
		setEditUser(tokenInfo?.tokenProps?.authDetails);
	});
	const ws = useSelector((state) => state);

	const { data, isLoading, isError }: any = useGetCurrentAssociationDataQuery({
		docType: formData.DocType.toUpperCase(),
		paCode: pacode,
		salesCode: salesCode,
		countryCode: countryCode,
		preferredLanguage: preferredLanguage,
		wslId: wslId,
	});

	useEffect(() => {
		setComplianceData(data?.data.currentComplaince);
	}, [data]);

	const sortVerbiage = (array: typeof dataVerbiage) => {
		const sortOrder: any = {
			"Charging Infrastructure": 0,
			Training: 1,
			"Service Capacity": 2,
			"Retail Environment": 3,
			eCommerce: 4,
			GuestXP: 5,
		};
		return [...array].sort((a, b) => {
			return sortOrder[a] - sortOrder[b];
		});
	};
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	if (data && data.docId) {
	// 		dispatch(setDocument({ docId: data.docId }));
	// 	}
	// });
	// Actual Client Data should be passed as 'dataVerbiage' to this component. 'sortedPicks' variable should be mapped in return instead of 'sortedDummyData'
	const sortedPicks = sortVerbiage(dataVerbiage);

	const dummyData = ["Training", "Service Capacity", "eCommerce", "GuestXP"];

	const sortedDummyData = sortVerbiage(dummyData);

	return (
		<div className={"fds-m--x-2 fds-m--y-2"}>
			<Col>
				<p className={"fds-p--t-1 fds-p--b-2 fds-weight--bold"}>Current Competencies Section Associations</p>
				{complianceData?.map((item: any, index: any) => {
					return (
						<p
							className={"fds-p--y-1 fds-weight--medium"}
							// key={item.indexOf(item) + 1}
							key={index}
						>
							{/* {item} */}
							{item.groupName}
						</p>
					);
				})}
			</Col>
		</div>
	);
};

export default EditChosenAssociations;
