// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useMemo, createContext, useContext } from "react";
import decode from "jwt-decode";

import useQueryParams from "hooks/useQueryParams";
import Cookie from "utils/CookieUtils";
import { useGetAuthDataQuery } from "services/auth";
import { setPacode } from "reducers/pacode.slice";
import { setUserPreferencesNotification } from "reducers/notifications.slice";
import { setUserType } from "reducers/user.slice";
import { useDispatch } from "react-redux";
import { setPreferredLanguage, setCountryCode } from "reducers/locale-preferences.slice";

interface AuthProviderProps {
	children: JSX.Element;
}

export enum MMedUserTypes {
	Admin = "Admin",
	Dealer = "Dealer",
	Employee = "Employee",
}

interface AuthContextProps {
	isAuthenticated: boolean;
	userType: MMedUserTypes;
	isAdmin: boolean;
	isDealer: boolean;
	isEmployee: boolean;
	loginWithRedirect: () => void;
	logout: () => void;
	tokenProps: TokenInfoProps;
	accessToken: string;
}

export interface TokenInfoProps {
	tokenDetails: Token;
	authDetails: AuthResponseProps;
}

export interface AuthResponseProps {
	isAuthorized: boolean;
	userType: string;
	wslId: string;
	userJobRole: string;
	businessName?: string;
	tierName?: string | null;
	firstName: string;
	lastName: string;
	countryCode: string;
	preferredLanguage: string;
	isUserEmailPreferenceSet: boolean;
	salesCode: string | null;
	paCode: string | null;
	entitlementRole: string[];
	mail: string;
	telephoneNumber: string;
}

export interface Token {
	aud: string;
	iss: string;
	iat: number;
	exp: number;
	CommonName: string;
	sub: string;
	uid: string;
	memberof: string;
	givenName: string;
	sn: string;
	mail: string;
	l: string;
	st: string;
	c: string;
	telephoneNumber: string;
	preferredLanguage: string;
	fordStatus: string;
	covisid: string;
	fordSiteCode: string;
	fordUserType: string;
	apptype: string;
	appid: string;
	authmethod: string;
	auth_time: string;
	ver: string;
}

// const AUTH_REDIRECT_HOST = `${origin}/oauth/redirect`;
let _token: string | null = null;

export const AuthContext = createContext<AuthContextProps | null>(null);

const SECS_IN_A_DAY = 86400;

export const getAccessToken = () => _token || Cookie.get("MMED_ACCESS_TOKEN") || localStorage.getItem("MMED_ACCESS_TOKEN");

export const getAccessTokenData = (): Token => {
	try {
		return decode(getAccessToken() ?? "");
	} catch (e) {
		return {} as Token;
	}
};

export const getIdToken = () => Cookie.get("MMED_ID_TOKEN");

export const getAuthInfo = (): AuthResponseProps => {
	try {
		const info = JSON.parse(Cookie.get("MMED_AUTH_INFO") ?? "");
		return info;
	} catch (e) {
		return {} as AuthResponseProps;
	}
};

// export const getDealerLangCode = (): string => localStorage.getItem("MMED_DEALER_LANG_CODE") ?? "en";
export const getDealerLangCode = (): string => "en";

// export const getDealerCountryCode = (): string => localStorage.getItem("MMED_DEALER_COUNTRY_CODE") ?? "USA";
export const getDealerCountryCode = (): string => "USA";

export const getTokenExpires = (mExpiresIn: string) => {
	return Number(mExpiresIn) / SECS_IN_A_DAY;
};

export const getTokenAuthDetails = () => {
	const tokenData = getAccessTokenData(); // This should return TokenInfoProps which includes both token and auth details
	const authInfo = tokenData.authDetails;
	const tokenDetails = tokenData.tokenDetails;

	const wslId = authInfo?.wslId || tokenDetails?.uid;
	return {
		wslId: wslId,
		countryCode: getDealerCountryCode(),
		preferredLanguage: getDealerLangCode(),
	};
};

const AuthProvider = (props: AuthProviderProps) => {
	const { children } = props;
	const { accessToken, idToken, expiresIn } = useQueryParams();
	_token = accessToken;
	const dispatch = useDispatch();

	const loginWithRedirect = () => {
		// const redirectUri = `${AUTH_REDIRECT_HOST}`;
		localStorage.setItem("mmedNextUrl", window.location.href);
		const queryParams = [`client_id=${process.env.REACT_APP_AUTH_CLIENT_ID ?? ""}`, `resource=${process.env.REACT_APP_AUTH_RESOURCE_URN ?? ""}`, "response_type=token+id_token", `redirect_uri=${window.location.href}`].join("&");
		const oauthIssuerUrl = process.env.REACT_APP_AUTH_ISSUER_URL;
		window.location.href = `${oauthIssuerUrl ?? ""}/?${queryParams}`;
	};

	const logout = (reload = false) => {
		Cookie.remove("MMED_ACCESS_TOKEN");
		Cookie.remove("MMED_ID_TOKEN");
		Cookie.remove("MMED_TOKEN_EXPIRY");
		localStorage.removeItem("MMED_ACCESS_TOKEN");
		if (reload) window.location.reload();
	};

	const mExpiresAt = useMemo(() => {
		return expiresIn ? Date?.now() + Number(expiresIn) * 1000 : 0;
	}, [expiresIn]);

	const setTokens = async (mAccessToken: string, mIdToken: string, mExpiresIn: string) => {
		const expires = getTokenExpires(mExpiresIn);
		const tokenExpiry = mExpiresIn ? Date?.now() + Number(mExpiresIn) * 1000 : 0;
		localStorage.setItem("MMED_ACCESS_TOKEN", mAccessToken);
		Cookie.set("MMED_ACCESS_TOKEN", mAccessToken, { expires });
		Cookie.set("MMED_ID_TOKEN", mIdToken, { expires });
		Cookie.set("MMED_TOKEN_EXPIRY", tokenExpiry.toString(), {
			expires,
		});
		return Promise.resolve();
	};

	if (getAuthInfo().isAuthorized) {
		return <h1 style={{ textAlign: "center", padding: "24px 12px" }}>User Un-Authorized</h1>;
	}

	const isAuthenticated: boolean = useMemo(() => {
		const aTkn = accessToken || getAccessToken();
		const idTkn = idToken || getIdToken();
		const expiresAt = idToken ? mExpiresAt : Cookie.get("MMED_TOKEN_EXPIRY");
		const isValid = Number(expiresAt) > Date.now();
		return !!(aTkn && idTkn && isValid);
	}, [accessToken, idToken, expiresIn, mExpiresAt]);

	const mAccessToken = useMemo(() => {
		return getAccessToken() ?? "";
	}, [isAuthenticated]);

	const {
		isFetching: isAuthDataFetching,
		isLoading: isAuthDataLoading,
		isError,
		isSuccess,
		data: authInfo,
	} = useGetAuthDataQuery("", {
		skip: isAuthenticated !== true,
	});

	useEffect(() => {
		if (accessToken && idToken) {
			setTokens(accessToken, idToken, expiresIn)
				.then(() => {
					const nextURL = localStorage.getItem("mmedNextUrl");
					localStorage.removeItem("mmedNextUrl");
					window.location.href = nextURL || origin;
				})
				// eslint-disable-next-line no-console
				.catch((e) => console.log(e));
		}
	}, [accessToken]);

	const isUserEmailPreferenceSet = useMemo(() => {
		return authInfo ? authInfo.isUserEmailPreferenceSet : false;
	}, [authInfo]);

	useEffect(() => {
		const authDetails = authInfo as AuthResponseProps;

		// If the user is not authorized, log them out.
		if (authDetails?.isAuthorized === false) {
			logout(false);
		}

		// If there are authDetails, perform several state updates.
		if (authDetails) {
			// Dispatch actions to update Redux state for userType.
			dispatch(setUserType(authDetails.userType));

			// Dispatch actions to update Redux state for preferredLanguage and countryCode.
			// dispatch(setPreferredLanguage(authDetails?.preferredLanguage));
			dispatch(setPreferredLanguage("en"));
			// dispatch(setCountryCode(authDetails?.countryCode));
			dispatch(setCountryCode("USA"));
			//

			// Dispatch actions to update Redux state for paCode.
			dispatch(setPacode({ wslId: authDetails.wslId }));

			// Check if the user is a dealer and their email preference is not set.
			const isDealer = authDetails.userType === MMedUserTypes.Dealer;
			const showNotificationForDealer = isDealer && !authDetails.isUserEmailPreferenceSet;

			// Dispatch action to set user preference notification.
			dispatch(setUserPreferencesNotification(showNotificationForDealer));
		}
	}, [authInfo, dispatch]);

	const userType: MMedUserTypes = useMemo(() => {
		const authDetails = authInfo as AuthResponseProps;

		if (authDetails?.userType?.toLowerCase() === MMedUserTypes.Employee.toLocaleLowerCase()) return MMedUserTypes.Employee;
		if (authDetails?.userType?.toLowerCase() === MMedUserTypes.Admin.toLocaleLowerCase()) return MMedUserTypes.Admin;
		return MMedUserTypes.Dealer;
	}, [authInfo]);

	// Placeholder for loading state
	if (isAuthenticated && (isAuthDataFetching || isAuthDataLoading)) {
		return <p style={{ textAlign: "center", padding: "24px 12px" }}>Loading...</p>;
	}

	const tokenProps = {
		tokenDetails: getAccessTokenData(),
		authDetails: authInfo as AuthResponseProps,
	};

	return (
		<>
			<AuthContext.Provider
				value={{
					loginWithRedirect,
					tokenProps,
					isAuthenticated,
					userType,
					isAdmin: userType === MMedUserTypes.Admin,
					isDealer: userType === MMedUserTypes.Dealer,
					isEmployee: userType === MMedUserTypes.Employee,
					accessToken: mAccessToken,
					logout,
					isUserEmailPreferenceSet,
				}}
			>
				{children}
			</AuthContext.Provider>
		</>
	);
};

export const useAuth = (): AuthContextProps => useContext(AuthContext) as AuthContextProps;

export default AuthProvider;
