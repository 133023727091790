import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Tag, TopBanner } from "components/components";
import { useSelector } from "react-redux";
import { RootState } from "store";
import AttestationsChargingInfraForm from "./components/AttestationsChargingInfraForm";
import AttestationsToolsAndEquimentForm from "./components/AttestationsToolsAndEquipmentForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";

type BannerTypes = "default" | "error" | "success" | "warning";
interface TopBannerFn {
	message: string;
	status?: BannerTypes;
}

const ManageAttestation = ({ mode = 0, chargingInfraData, toolsEquipmentData, dealerInfo, paCode, updateAttestationsCache }: any) => {
	const windowWidth = useSelector((state: RootState) => state.windowSize.width);
	const navigate = useNavigate();
	const location = useLocation();
	const { attestationType } = useParams();

	const [successBanner, setSuccessBanner] = useState<any>(false);
	const editMode = location.pathname?.includes("edit-attestation");
	const disableCI = (editMode && !chargingInfraData) || (!editMode && !!chargingInfraData);
	const disableTE = (editMode && !toolsEquipmentData) || (!editMode && !!toolsEquipmentData);

	const [attestationForm, setAttestationForm] = useState("charging");

	useEffect(() => {
		if ((attestationForm === "charging" && disableCI && editMode) || (attestationForm === "tools" && disableTE && editMode)) {
			navigate(`/${paCode}/add-attestation`);
		}
		if ((attestationForm === "charging" && disableCI && !editMode) || (attestationForm === "tools" && disableTE && !editMode)) {
			navigate(`/${paCode}/edit-attestation`);
		}
	}, [attestationForm, disableCI, disableTE, editMode]);

	const switchForm = (e: any) => {
		const formSelected = e.target;
		setAttestationForm(formSelected.value);
	};

	const showSuccessBanner = ({ message, status = "success" }: TopBannerFn) => {
		setSuccessBanner({ message, status });
	};

	useEffect(() => {
		if (attestationType) {
			const type = attestationType === "tools-equipment" ? "tools" : "charging";
			// console.log({ attestationType, type });
			setAttestationForm(type);
		}
	}, [attestationType]);

	return (
		<Fragment>
			{/* {(windowWidth as number) >= 1024 ? ( */}
			<Fragment>
				{successBanner ? (
					<TopBanner status={successBanner?.status} buttonClick={() => setSuccessBanner(false)} persistent>
						{successBanner?.message}
					</TopBanner>
				) : (
					""
				)}
				<Col xs={12} className={"fds-m--y-3"}>
					<Row className={"fds-flex--center fds-p--y-3"}>
						<div className={"fds-flex--center fds-flex__items--center fds-flex-direction--column  fds-p--x-0"}>
							<Tag tag={"h1"} className={"fmc-type--heading3 fds-color--primary fds-align--center fds-p--y-2"}>
								{editMode ? "Edit Attestation" : "Add Attestation"}
							</Tag>
							<Col xs={10} className={"fds-align--left"}>
								<p>Per eDC15038, all Dealers need to demonstrate fundamental Electric Vehicle Competency by June 30, 2025, in order to sell and service electric vehicles. Please attest you have the following:</p>
								<ol style={{ paddingLeft: 20 }}>
									<li>
										<p>Charging Infrastructure necessary to support the sale and service of electric vehicles</p>
									</li>
									<li>
										<p>Tools & Equipment necessary to safely and effectively perform electric vehicle service work</p>
									</li>
								</ol>
								<p style={{ paddingTop: 12 }}>
									Reminder: Charging and Tools & Equipment attestation forms may be submitted on different dates. You may not submit an attestation until you have installed your charging infrastructure or have acquired* all necessary tools and equipment
									(*forklift access).
								</p>
							</Col>
						</div>
					</Row>
					<Row className={"fds-flex--center"}>
						<Col xs={12}>
							<div className={"w-100"}>
								<Col xs={12} className={"fds-p--b-3"}>
									<fieldset className={"fmc-check"} style={{ maxWidth: "unset" }}>
										<legend className={"fds-color--gray3 "} style={{ fontSize: "1.1rem" }}>
											Select Attestation Form
										</legend>
										<label htmlFor={"attestation-CI"}>
											<input type={"radio"} checked={attestationForm === "charging"} id={"attestation-CI"} name={"attestationSelect"} value={"charging"} onChange={switchForm} defaultChecked={true} />
											<span className={"fds-color--primary"}>Charging Infrastructure</span>
										</label>
										<label htmlFor={"attestation-Tools"}>
											<input type={"radio"} checked={attestationForm === "tools"} id={"attestation-Tools"} name={"attestationSelect"} value={"tools"} onChange={switchForm} />
											<span className={"fds-color--primary"}>Tools & Equipment</span>
										</label>
									</fieldset>
								</Col>
								{attestationForm == "charging" ? (
									<div>
										<AttestationsChargingInfraForm editMode={editMode} paCode={paCode} defaultValues={chargingInfraData} showSuccessBanner={showSuccessBanner} dealerInfo={dealerInfo} updateAttestationsCache={updateAttestationsCache} />
									</div>
								) : (
									<div>
										<AttestationsToolsAndEquimentForm editMode={editMode} paCode={paCode} defaultValues={toolsEquipmentData} showSuccessBanner={showSuccessBanner} dealerInfo={dealerInfo} updateAttestationsCache={updateAttestationsCache} />
									</div>
								)}
							</div>
						</Col>
					</Row>
				</Col>
			</Fragment>
		</Fragment>
	);
};

export default ManageAttestation;
